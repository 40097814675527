<template>
  <v-container v-if="vueRelatoriosCobranca">
    <PageToolbar title="Lista de Atrasados" icon="description" dark />

    <v-card class="mt-6">
      <v-card-title class="px-6 subtitle-1 lightgray">
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="search"
          label="Buscar"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text"
          depressed
          small
          color="toolbar"
          @click="gerarExcel"
          :disabled="dados.length ? false : true"
        >
          <v-icon left>get_app</v-icon>
          Excel
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="dados"
          :search="search"
          :expanded.sync="expanded"
          single-expand
          show-expand
          item-key="index"
          :items-per-page="15"
          class="data-tables"
        >
          <template v-slot:item.data_vencimento="{ item }">
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.saldo="{ item }">
            {{ item.saldo | _guarani }}
          </template>

          <template v-slot:expanded-item="{ item }">
            <td class="text-center">
              <v-icon>subdirectory_arrow_right</v-icon>
            </td>
            <td class="pa-0" :colspan="headers.length">
              <v-simple-table class="sub-data-tables">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="(header, index) in expandedHeaders"
                        :key="index"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="(use, index) in expandedHeaders" :key="index">
                        {{ item[use.value] }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>

          <template v-slot:body.append="{ headers }">
            <tr v-if="totalGuarani" class="font-weight-bold">
              <td class="text-right">Total:</td>
              <td :colspan="headers.length - 3"></td>
              <td class="text-right">G$</td>
              <td class="text-right">
                {{ totalGuarani | _guarani }}
              </td>
            </tr>
            <tr v-if="totalReal" class="font-weight-bold">
              <td class="text-right">Total:</td>
              <td :colspan="headers.length - 3"></td>
              <td class="text-right">R$</td>
              <td class="text-right">
                {{ totalReal | _real }}
              </td>
            </tr>
            <tr v-if="totalDolar" class="font-weight-bold">
              <td class="text-right">Total:</td>
              <td :colspan="headers.length - 3"></td>
              <td class="text-right">U$</td>
              <td class="text-right">
                {{ totalDolar | _real }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
    </v-card>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { getListaAtrasados } from "@/services/api/relatorios.api.js";
import { excelDownload } from "@/helpers/excel.js";
import { mapGetters } from "vuex";
import { format, parseISO } from "date-fns";

export default {
  name: "ListaAtrasados",

  data() {
    return {
      loading: false,
      dados: [],
      expanded: [],
      search: "",
      totalDolar: null,
      totalReal: null,
      totalGuarani: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueRelatoriosCobranca() {
      return this.getAccess("vueRelatoriosCobranca", "visualizar");
    },
    headers() {
      return [
        {
          text: "id",
          value: "id_cliente",
        },
        {
          text: "Cliente",
          value: "nome",
        },
        {
          text: "celular",
          value: "celular",
        },
        {
          text: "cidade",
          value: "cidade",
        },
        {
          text: "class",
          value: "classificacao",
        },
        {
          text: "venc",
          value: "data_vencimento",
        },
        {
          text: "dias",
          value: "dias",
        },
        {
          text: "moeda",
          align: "right",
          value: "moeda",
        },
        {
          text: "saldo",
          align: "right",
          value: "saldo",
        },
      ];
    },
    expandedHeaders() {
      return [
        {
          text: "Login Radius",
          value: "pppoe",
        },
        {
          text: "endereco",
          value: "endereco",
        },
      ];
    },
  },

  methods: {
    getDados() {
      this.loading = true;
      getListaAtrasados()
        .then((response) => {
          this.dados = response.map((item, index) => {
            item.index = index;
            return item;
          });

          this.totalDolar = response
            .filter(({ moeda }) => moeda === "U$")
            .reduce((accumulator, item) => accumulator + Number(item.saldo), 0);

          this.totalReal = response
            .filter(({ moeda }) => moeda === "R$")
            .reduce((accumulator, item) => accumulator + Number(item.saldo), 0);

          this.totalGuarani = response
            .filter(({ moeda }) => moeda === "G$")
            .reduce((accumulator, item) => accumulator + Number(item.saldo), 0);

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    gerarExcel() {
      const tHeader = [
        "ID",
        "CLIENTE",
        "CELULAR",
        "ENDERECO",
        "CIDADE",
        "CLASS",
        "LOGIN RADIUS",
        "VENCIMENTO",
        "DIAS",
        "MOEDA",
        "SALDO",
      ];
      const filterVal = [
        "id_cliente",
        "nome",
        "celular",
        "endereco",
        "cidade",
        "classificacao",
        "pppoe",
        "data_vencimento",
        "dias",
        "moeda",
        "saldo",
      ];
      const list = this.dados.map((item) => {
        let obj = { ...item };
        obj.data_vencimento = format(
          parseISO(obj.data_vencimento),
          "dd/MM/yyyy"
        );
        obj.saldo = this.$options.filters._guarani(obj.saldo);

        return obj;
      });
      const date = new Date();
      const now = format(date, "dd-MM-yyyy");
      const hrs = format(date, "HH");
      const mm = format(date, "mm");
      const ss = format(date, "ss");

      const filename = `NETI-ListaDeAtrasados${now}_${hrs}h_${mm}m_${ss}s`;
      this.$Progress.start();
      this.loading = true;
      excelDownload(tHeader, filterVal, list, filename).then(() => {
        this.$Progress.finish();
        this.loading = false;
      });
    },
  },

  mounted() {
    if (this.vueRelatoriosCobranca) {
      this.getDados();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr {
  &.v-data-table__expanded__row {
    background: #eeeeee;
    margin-top: 10px;
    td {
      border-top: 0px solid #dddddd;

      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }

  &.v-data-table__expanded__content {
    margin-bottom: 10px;
    box-shadow: none;
    td {
      border-bottom: none;
    }
  }
}

::v-deep .sub-data-tables {
  border-bottom: 0px solid #dddddd;
  thead {
    background: #eeeeee;
    th {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
  tbody {
    td {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
}
</style>
