var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vueRelatoriosCobranca)?_c('v-container',[_c('PageToolbar',{attrs:{"title":"Lista de Atrasados","icon":"description","dark":""}}),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',{staticClass:"px-6 subtitle-1 lightgray"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"search","label":"Buscar","clearable":"","solo":"","flat":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","small":"","color":"toolbar","disabled":_vm.dados.length ? false : true},on:{"click":_vm.gerarExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("get_app")]),_vm._v(" Excel ")],1)],1),_c('v-divider'),_c('v-card-text',[(!_vm.loading)?_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.dados,"search":_vm.search,"expanded":_vm.expanded,"single-expand":"","show-expand":"","item-key":"index","items-per-page":15},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_vencimento,"dd/MM/yyyy"))+" ")]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("_guarani")(item.saldo))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-icon',[_vm._v("subdirectory_arrow_right")])],1),_c('td',{staticClass:"pa-0",attrs:{"colspan":_vm.headers.length}},[_c('v-simple-table',{staticClass:"sub-data-tables",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.expandedHeaders),function(header,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',[_c('tr',_vm._l((_vm.expandedHeaders),function(use,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item[use.value])+" ")])}),0)])]},proxy:true}],null,true)})],1)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [(_vm.totalGuarani)?_c('tr',{staticClass:"font-weight-bold"},[_c('td',{staticClass:"text-right"},[_vm._v("Total:")]),_c('td',{attrs:{"colspan":headers.length - 3}}),_c('td',{staticClass:"text-right"},[_vm._v("G$")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("_guarani")(_vm.totalGuarani))+" ")])]):_vm._e(),(_vm.totalReal)?_c('tr',{staticClass:"font-weight-bold"},[_c('td',{staticClass:"text-right"},[_vm._v("Total:")]),_c('td',{attrs:{"colspan":headers.length - 3}}),_c('td',{staticClass:"text-right"},[_vm._v("R$")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("_real")(_vm.totalReal))+" ")])]):_vm._e(),(_vm.totalDolar)?_c('tr',{staticClass:"font-weight-bold"},[_c('td',{staticClass:"text-right"},[_vm._v("Total:")]),_c('td',{attrs:{"colspan":headers.length - 3}}),_c('td',{staticClass:"text-right"},[_vm._v("U$")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("_real")(_vm.totalDolar))+" ")])]):_vm._e()]}}],null,false,2261482783)}):_c('Carregando')],1)],1)],1):_c('NaoAutorizado')}
var staticRenderFns = []

export { render, staticRenderFns }